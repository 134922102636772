import { makeApi, Zodios } from '@zodios/core';
import { z } from 'zod';
import { axios } from './axios';

export const Advertisement = z.object({
  title: z.string().max(255),
  body: z.string(),
  logo: z.string().url(),
  cta_text: z.string().max(25),
  cta_link: z.string().max(200).url(),
  position: z.number().int().lte(65535),
});
export const MetaProduct = z.object({
  id: z.number().int(),
  name: z.string().max(150),
  iva: z.number().int().gte(-2147483648).lte(2147483647).optional(),
  icon: z.string().max(16).nullish(),
  logo: z.string().url().nullish(),
});
export const Family = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const Variety = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const Product = z.object({
  id: z.number().int(),
  meta_product: MetaProduct,
  meta_product_id: z.number().int().optional(),
  family: Family,
  family_id: z.number().int().optional(),
  variety: Variety,
  variety_id: z.number().int().optional(),
  other_variety: z.string().max(150).nullish(),
  other_family: z.string().max(150).nullish(),
});
export const KindEnum = z.enum(['ABASTORES', 'NATIONAL', 'INTERNATIONAL']);
export const DataSource = z.object({
  id: z.number().int(),
  name: z.string().max(255),
  link: z.string().max(200).url().nullish(),
  kind: KindEnum.optional(),
});
export const Province = z.object({
  id: z.number().int(),
  product_count: z.number().int(),
  name: z.string().max(255),
});
export const ConditionEnum = z.enum(['GT', 'LT', 'AE']);
export const Alert = z.object({
  id: z.number().int(),
  create_dt: z.string(),
  update_dt: z.string(),
  active: z.boolean(),
  product: Product,
  product_id: z.number().int().nullish(),
  data_source: DataSource,
  data_source_id: z.number().int().nullish(),
  province: Province,
  province_id: z.number().int().nullish(),
  condition: ConditionEnum,
  value: z.string().regex(/^-?\d{0,5}(?:\.\d{0,3})?$/),
});
export const PaginatedAlertList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Alert),
  })
  .partial();
export const PatchedAlert = z
  .object({
    id: z.number().int(),
    create_dt: z.string(),
    update_dt: z.string(),
    active: z.boolean(),
    product: Product,
    product_id: z.number().int().nullable(),
    data_source: DataSource,
    data_source_id: z.number().int().nullable(),
    province: Province,
    province_id: z.number().int().nullable(),
    condition: ConditionEnum,
    value: z.string().regex(/^-?\d{0,5}(?:\.\d{0,3})?$/),
  })
  .partial();
export const IssueType = z.object({
  id: z.number().int(),
  name: z.string().max(250),
});
export const IssueSubject = z.object({
  id: z.number().int(),
  name: z.string().max(250),
});
export const IssueSubjectOption = z.object({
  id: z.number().int(),
  subject: IssueSubject,
  title: z.string().max(500),
});
export const Profile = z.object({
  id: z.number().int(),
  name: z.string().max(150),
  color: z.string().max(20).optional(),
  are_sale_interests_public: z.boolean().optional(),
  can_see: z.array(z.number()).optional(),
});
export const Country = z.object({
  id: z.number().int(),
  name: z.string().max(40).optional(),
  code: z.string().max(2).optional(),
});
export const State = z.object({
  id: z.number().int(),
  country: Country,
  name: z.string().max(165),
  code: z.string().max(8).optional(),
});
export const Locality = z.object({
  id: z.number().int(),
  state: State,
  name: z.string().max(165),
  postal_code: z.string().max(10),
});
export const Address = z.object({
  id: z.number().int(),
  locality: Locality,
  street_number: z.string().max(20).optional(),
  route: z.string().max(100).optional(),
  raw: z.string().max(200),
  formatted: z.string().max(200).optional(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});
export const UserRestricted = z.object({
  id: z.number().int(),
  profile: Profile,
  address: Address,
  average_rating: z.number(),
  n_buys: z.number().int(),
  n_sales: z.number().int(),
});
export const Pack = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const Container = z.object({
  id: z.number().int(),
  pack: Pack,
  pack_id: z.number().int(),
  capacity: z.number(),
  increment_price: z.number().nullish(),
});
export const BaseAction = z.object({
  id: z.number().int(),
  container: Container,
  container_id: z.number().int().nullish(),
  reference: z.string().uuid(),
  value: z.number().nullish(),
  quantity: z.number(),
  winner: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  hot: z.boolean().optional(),
  hot_reject: z.boolean().optional(),
  expirate_dt: z.string().nullish(),
  polymorphic_ctype: z.number().int().nullable(),
});
export const Issue = z.object({
  id: z.number().int(),
  type: IssueType,
  type_id: z.number().int(),
  subject_option: IssueSubjectOption,
  subject_option_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  process: BaseAction,
  process_id: z.number().int(),
  title: z.string().max(250),
  description: z.string().nullish(),
  comment: z.string().nullish(),
  solved: z.boolean().optional(),
  solved_comment: z.string().nullish(),
  create_dt: z.string(),
  update_dt: z.string(),
  other_subject_option: z.string().nullish(),
});
export const PaginatedIssueList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Issue),
  })
  .partial();
export const PaginatedIssueSubjectOptionList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(IssueSubjectOption),
  })
  .partial();
export const PaginatedIssueSubjectList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(IssueSubject),
  })
  .partial();
export const PaginatedIssueTypeList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(IssueType),
  })
  .partial();
export const StatusEnum = z.enum(['PE', 'SE']);
export const Region = z.object({
  id: z.number().int(),
  name: z.string().max(255),
});
export const DataPrice = z.object({
  id: z.number().int(),
  product: Product,
  province: Province,
  region: Region,
  data_source: DataSource,
  last_deviation: z
    .object({ date: z.string(), deviation: z.number() })
    .nullable(),
  date: z.string(),
  price: z.number(),
  quantity: z.number().nullish(),
});
export const Notification = z.object({
  id: z.number().int(),
  create_dt: z.string(),
  triggered_by: Alert,
  status: StatusEnum,
  read: z.boolean().optional(),
  data_price: DataPrice,
});
export const PaginatedNotificationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Notification),
  })
  .partial();
export const PatchedNotification = z
  .object({
    id: z.number().int(),
    create_dt: z.string(),
    triggered_by: Alert,
    status: StatusEnum,
    read: z.boolean(),
    data_price: DataPrice,
  })
  .partial();
export const Login = z.object({
  username: z.string().optional(),
  email: z.string().email().optional(),
  password: z.string(),
});
export const User = z.object({
  id: z.number().int(),
  username: z
    .string()
    .max(150)
    .regex(/^[\w.@+-]+$/),
  email: z.string().max(254).email().optional(),
  cif_nif: z.string().max(150).nullish(),
  company_name: z.string().max(300).nullish(),
  phone_number: z
    .string()
    .max(17)
    .regex(/^\+?1?\d{9,15}$/)
    .nullish(),
  has_notifications: z.boolean().optional(),
  legal_terms_ok: z.boolean().optional(),
  marketing_offers_ok: z.boolean().optional(),
  stripe_account_id: z.string().max(150).nullish(),
  stripe_account_url: z.string().max(200).url().nullish(),
  first_name: z.string().max(150).optional(),
  last_name: z.string().max(150).optional(),
  is_active: z.boolean().optional(),
  is_staff: z.boolean().optional(),
  is_superuser: z.boolean().optional(),
  profile: Profile,
  profile_id: z.number().int(),
  interests: z.string(),
  interests_id: z.array(z.number()),
  address: Address,
  address_data: z.string().optional(),
  billing_address: Address,
  billing_address_data: z.string().optional(),
  date_joined: z.string().optional(),
  last_login: z.string().nullish(),
  n_auctions: z.number().int(),
  n_demands: z.number().int(),
  average_rating: z.number(),
  n_buys: z.number().int(),
  n_sales: z.number().int(),
  avg_service: z.number(),
  avg_quality: z.number(),
  avg_response: z.number(),
});
export const JWT = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  user: User,
});
export const RestAuthDetail = z.object({ detail: z.string() });
export const PasswordChange = z.object({
  old_password: z.string().max(128),
  new_password1: z.string().max(128),
  new_password2: z.string().max(128),
});
export const PasswordReset = z.object({ email: z.string().email() });
export const PasswordResetConfirm = z.object({
  new_password1: z.string().max(128),
  new_password2: z.string().max(128),
  uid: z.string(),
  token: z.string(),
});
export const RegisterUser = z.object({
  username: z.string().min(1).max(150),
  email: z.string().email(),
  password1: z.string(),
  password2: z.string(),
  first_name: z.string().max(150).optional(),
  last_name: z.string().max(150).optional(),
  cif_nif: z.string().max(150).nullish(),
  phone_number: z
    .string()
    .max(17)
    .regex(/^\+?1?\d{9,15}$/)
    .nullish(),
  legal_terms_ok: z.boolean().optional(),
  marketing_offers_ok: z.boolean().optional(),
  profile: z.number().int(),
});
export const VerifyEmail = z.object({ key: z.string() });
export const TokenRefresh = z.object({
  access: z.string(),
  refresh: z.string(),
});
export const TokenVerify = z.object({ token: z.string() });
export const PatchedUser = z
  .object({
    id: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().max(254).email(),
    cif_nif: z.string().max(150).nullable(),
    company_name: z.string().max(300).nullable(),
    phone_number: z
      .string()
      .max(17)
      .regex(/^\+?1?\d{9,15}$/)
      .nullable(),
    has_notifications: z.boolean(),
    legal_terms_ok: z.boolean(),
    marketing_offers_ok: z.boolean(),
    stripe_account_id: z.string().max(150).nullable(),
    stripe_account_url: z.string().max(200).url().nullable(),
    first_name: z.string().max(150),
    last_name: z.string().max(150),
    is_active: z.boolean(),
    is_staff: z.boolean(),
    is_superuser: z.boolean(),
    profile: Profile,
    profile_id: z.number().int(),
    interests: z.string(),
    interests_id: z.array(z.number()),
    address: Address,
    address_data: z.string(),
    billing_address: Address,
    billing_address_data: z.string(),
    date_joined: z.string(),
    last_login: z.string().nullable(),
    n_auctions: z.number().int(),
    n_demands: z.number().int(),
    average_rating: z.number(),
    n_buys: z.number().int(),
    n_sales: z.number().int(),
    avg_service: z.number(),
    avg_quality: z.number(),
    avg_response: z.number(),
  })
  .partial();
export const PaginatedAddressList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Address),
  })
  .partial();
export const PaginatedProfileList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Profile),
  })
  .partial();
export const PaginatedUserList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(User),
  })
  .partial();
export const Question = z.object({
  id: z.number().int(),
  order: z.number().int(),
  question: z.string().max(250),
  answer: z.string(),
});
export const PaginatedQuestionList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Question),
  })
  .partial();
export const TermBase = z.object({
  id: z.number().int(),
  name: z.string().max(250),
});
export const Term = z.object({
  id: z.number().int(),
  base: TermBase,
  title: z.string().max(500),
  description: z.string(),
});
export const PaginatedTermList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Term),
  })
  .partial();
export const PaginatedTermBaseList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(TermBase),
  })
  .partial();
export const AdminDataProduct = z.object({
  id: z.number().int(),
  meta_product: MetaProduct,
  meta_product_id: z.number().int().optional(),
  family: Family,
  family_id: z.number().int().optional(),
  variety: Variety,
  variety_id: z.number().int().optional(),
  other_variety: z.string().max(150).nullish(),
  other_family: z.string().max(150).nullish(),
});
export const AdminDataPrice = z.object({
  id: z.number().int(),
  product: AdminDataProduct.optional(),
  product_id: z.number().int().optional(),
  province: Province.optional(),
  province_id: z.number().int().optional(),
  region: Region.optional(),
  region_id: z.number().int().optional(),
  data_source: DataSource.optional(),
  data_source_id: z.number().int().optional(),
  date: z.string(),
  price: z.number(),
  quantity: z.number().nullish(),
});
export const PaginatedAdminDataPriceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(AdminDataPrice),
  })
  .partial();
export const PatchedAdminDataPrice = z
  .object({
    id: z.number().int(),
    product: AdminDataProduct,
    product_id: z.number().int(),
    province: Province,
    province_id: z.number().int(),
    region: Region,
    region_id: z.number().int(),
    data_source: DataSource,
    data_source_id: z.number().int(),
    date: z.string(),
    price: z.number(),
    quantity: z.number().nullable(),
  })
  .partial();
export const PaginatedDataSourceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DataSource),
  })
  .partial();
export const PatchedDataSource = z
  .object({
    id: z.number().int(),
    name: z.string().max(255),
    link: z.string().max(200).url().nullable(),
    kind: KindEnum,
  })
  .partial();
export const PaginatedProvinceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Province),
  })
  .partial();
export const PaginatedRegionList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Region),
  })
  .partial();
export const GroupDataDeviation = z.object({
  province: Province,
  region: Region,
});
export const PaginatedDataPriceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DataPrice),
  })
  .partial();
export const LeanDataPrice = z.object({ date: z.string(), price: z.number() });
export const PaginatedLeanDataPriceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(LeanDataPrice),
  })
  .partial();
export const ProductDataPrice = z.object({
  product: Product,
  max: z.number(),
  min: z.number(),
  avg: z.number(),
  date: z.string(),
  difference: z.number(),
});
export const GroupDataPrice = z.object({
  province: Province,
  region: Region,
  data_prices: z.array(ProductDataPrice),
});
export const Use = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const Certificate = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const AuctionSerializerRef = z.object({
  id: z.number().int(),
  uses: z.array(Use),
  uses_id: z.array(z.number()),
  certificates: z.array(Certificate),
  certificates_id: z.array(z.number()),
  containers: z.array(Container),
  product: Product,
  product_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  origin: Address,
  n_bids: z.string(),
  bids: z.string(),
  higher_bid_value: z.string(),
  higher_bid_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  pickup_dt: z.string().nullish(),
  pickup_dt_finish: z.string().nullish(),
  is_destination: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const AuctionSerializerList = z.object({
  id: z.number().int(),
  product: Product,
  containers: z.array(Container),
  certificates: z.array(Certificate),
  uses: z.array(Use),
  user: UserRestricted,
  origin: Address,
  n_bids: z.string(),
  bids: z.string(),
  higher_bid_value: z.string(),
  higher_bid_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  pickup_dt: z.string().nullish(),
  pickup_dt_finish: z.string().nullish(),
  is_destination: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const PaginatedAuctionSerializerListList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(AuctionSerializerList),
  })
  .partial();
export const Auction = z.object({
  id: z.number().int(),
  uses: z.array(Use),
  uses_id: z.array(z.number()),
  certificates: z.array(Certificate),
  certificates_id: z.array(z.number()),
  containers: z.array(Container),
  product: Product,
  product_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  origin: Address,
  origin_data: z.string(),
  n_bids: z.string(),
  bids: z.string(),
  higher_bid_value: z.string(),
  higher_bid_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  pickup_dt: z.string().nullish(),
  pickup_dt_finish: z.string().nullish(),
  is_destination: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const Bid = z.object({
  id: z.number().int(),
  container: Container,
  container_id: z.number().int().nullish(),
  user: UserRestricted,
  user_id: z.number().int(),
  reference: z.string().uuid(),
  value: z.number().nullish(),
  quantity: z.number(),
  winner: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  hot: z.boolean().optional(),
  hot_reject: z.boolean().optional(),
  expirate_dt: z.string().nullish(),
  distance: z.number().nullish(),
  polymorphic_ctype: z.number().int().nullable(),
  auction: z.number().int(),
});
export const PaginatedBidList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Bid),
  })
  .partial();
export const PaginatedCertificateList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Certificate),
  })
  .partial();
export const Offer = z.object({
  id: z.number().int(),
  container: Container,
  container_id: z.number().int().nullish(),
  user: UserRestricted,
  user_id: z.number().int(),
  reference: z.string().uuid(),
  value: z.number().nullish(),
  quantity: z.number(),
  winner: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  hot: z.boolean().optional(),
  hot_reject: z.boolean().optional(),
  expirate_dt: z.string().nullish(),
  distance: z.number().nullish(),
  has_samples: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  demand: z.number().int(),
});
export const Certification = z.object({
  reference: z.string().uuid(),
  auction: Auction,
  auction_id: z.number().int().optional(),
  offer: Offer,
  offer_id: z.number().int().optional(),
  subtotal: z.number().nullish(),
  total: z.number().nullish(),
  iva: z.number().nullish(),
  is_active: z.boolean().optional(),
  is_paid: z.boolean().optional(),
  is_send: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  certification_dt: z.string().nullish(),
});
export const PaginatedCertificationList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Certification),
  })
  .partial();
export const PaginatedContainerList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Container),
  })
  .partial();
export const DemandSerializerList = z.object({
  id: z.number().int(),
  product: Product,
  containers: z.array(Container),
  certificates: z.array(Certificate),
  uses: z.array(Use),
  user: UserRestricted,
  destination: Address,
  n_offers: z.string(),
  offers: z.string(),
  higher_offer_value: z.string(),
  higher_offer_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  production_dt: z.string().nullish(),
  production_dt_finish: z.string().nullish(),
  is_origin: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const PaginatedDemandSerializerListList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DemandSerializerList),
  })
  .partial();
export const Demand = z.object({
  id: z.number().int(),
  uses: z.array(Use),
  uses_id: z.array(z.number()),
  certificates: z.array(Certificate),
  certificates_id: z.array(z.number()),
  containers: z.array(Container),
  product: Product,
  product_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  destination: Address,
  destination_data: z.string(),
  offers: z.string(),
  n_offers: z.number().int(),
  higher_offer_value: z.string(),
  higher_offer_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  production_dt: z.string().nullish(),
  production_dt_finish: z.string().nullish(),
  is_origin: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const DemandSerializerRef = z.object({
  id: z.number().int(),
  uses: z.array(Use),
  uses_id: z.array(z.number()),
  certificates: z.array(Certificate),
  certificates_id: z.array(z.number()),
  containers: z.array(Container),
  product: Product,
  product_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  destination: Address,
  offers: z.string(),
  n_offers: z.number().int(),
  higher_offer_value: z.string(),
  higher_offer_quantity: z.string(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  production_dt: z.string().nullish(),
  production_dt_finish: z.string().nullish(),
  is_origin: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const PaginatedFamilyList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Family),
  })
  .partial();
export const Follow = z.object({
  id: z.number().int(),
  create_dt: z.string(),
  update_dt: z.string(),
  base_market_action: z.number().int(),
  user: z.number().int(),
});
export const PaginatedFollowList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Follow),
  })
  .partial();
export const PaginatedMetaProductList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(MetaProduct),
  })
  .partial();
export const PaginatedOfferList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Offer),
  })
  .partial();
export const PaginatedPackList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Pack),
  })
  .partial();
export const ParameterTemplate = z.object({
  id: z.number().int(),
  product: MetaProduct,
  product_id: z.number().int(),
  name: z.string().max(150),
  min_range: z.number().nullish(),
  max_range: z.number().nullish(),
  unit: z.string().max(150).nullish(),
});
export const PaginatedParameterTemplateList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ParameterTemplate),
  })
  .partial();
export const Parameter = z.object({
  id: z.number().int(),
  parameter_template: ParameterTemplate,
  parameter_template_id: z.number().int(),
  value: z.string().max(150),
  base_market_action: z.number().int(),
});
export const PaginatedParameterList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Parameter),
  })
  .partial();
export const PaginatedProductList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Product),
  })
  .partial();
export const Rating = z.object({
  id: z.number().int(),
  service: z.number().int().gte(1).lte(5).nullish(),
  response_rate: z.number().int().gte(1).lte(5).nullish(),
  quality: z.number().int().gte(1).lte(5).nullish(),
  create_dt: z.string(),
  update_dt: z.string(),
  message: z.string().nullish(),
  user_rating: z.number().int(),
  user_rated: z.number().int(),
  process: z.string().uuid().nullish(),
});
export const PaginatedRatingList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Rating),
  })
  .partial();
export const BaseMarketAction = z.object({
  id: z.number().int(),
  uses: z.array(Use),
  uses_id: z.array(z.number()),
  certificates: z.array(Certificate),
  certificates_id: z.array(z.number()),
  containers: z.array(Container),
  product: Product,
  product_id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  init_price: z.number().nullish(),
  min_quantity: z.number(),
  quantity: z.number(),
  has_samples: z.boolean().optional(),
  want_certificate: z.boolean().optional(),
  abastores_certificate: z.boolean().optional(),
  create_dt: z.string(),
  update_dt: z.string(),
  expirate_dt: z.string(),
  polymorphic_ctype: z.number().int().nullable(),
  follows: z.array(z.number()),
});
export const ShippingCompany = z.object({
  id: z.number().int(),
  name: z.string().max(150),
  package_type: z.string().max(150),
  logo: z.string().url().nullish(),
  rate_sampling: z.number().nullish(),
});
export const Sample = z.object({
  id: z.number().int(),
  user: UserRestricted,
  user_id: z.number().int(),
  base_market_action: BaseMarketAction,
  base_market_action_id: z.number().int(),
  shipping_company: ShippingCompany,
  shipping_company_id: z.number().int(),
  reference: z.string().uuid(),
  quantity: z.number(),
  subtotal: z.number().nullish(),
  total: z.number().nullish(),
  iva: z.number(),
  is_send: z.boolean().optional(),
  aprox_shipping_date: z.string().nullish(),
  create_dt: z.string(),
  update_dt: z.string(),
});
export const PaginatedSampleList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Sample),
  })
  .partial();
export const PaginatedShippingCompanyList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ShippingCompany),
  })
  .partial();
export const PaginatedUseList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Use),
  })
  .partial();
export const PaginatedVarietyList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(Variety),
  })
  .partial();
export const ProductStatusEnum = z.unknown();
export const BlankEnum = z.unknown();
export const NullEnum = z.unknown();
export const PaymentStatusEnum = z.unknown();
export const BuyProcess = z.object({
  id: z.string().uuid(),
  user: UserRestricted,
  base_action: z.string(),
  base_market_action: z.string(),
  rating: z.string(),
  payment_rate: z.number().optional(),
  subtotal: z.number().nullish(),
  total: z.number().nullish(),
  payment_rate_cost: z.number().nullish(),
  iva_cost: z.number().nullish(),
  container_cost: z.number().nullish(),
  difference_cost: z.number().nullish(),
  transport_cost: z.number().nullish(),
  transport_distance: z.number().nullish(),
  pick_up_dt: z.string().nullish(),
  delivery_dt: z.string().nullish(),
  payment_released_dt: z.string().nullish(),
  expiration_payment_dt: z.string().nullish(),
  expiration_transport_dt: z.string().nullish(),
  expiration_validation_dt: z.string().nullish(),
  create_dt: z.string(),
  update_dt: z.string(),
  commodity_weight: z.number().nullish(),
  final_weight: z.number().nullish(),
  difference_weight: z.number().nullish(),
  pick_up_commodity: z.boolean().optional(),
  active: z.boolean().optional(),
  is_validate_product: z.boolean().optional(),
  finished: z.boolean().optional(),
  product_status: z.union([ProductStatusEnum, BlankEnum, NullEnum]).nullish(),
  payment_status: z.union([PaymentStatusEnum, BlankEnum, NullEnum]).nullish(),
  polymorphic_ctype: z.number().int().nullable(),
});
export const PaginatedBuyProcessList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(BuyProcess),
  })
  .partial();
export const SaleProcess = z.object({
  id: z.string().uuid(),
  user: UserRestricted,
  base_action: z.string(),
  base_market_action: z.string(),
  rating: z.string(),
  buy_process: BuyProcess,
  payment_rate: z.number().optional(),
  subtotal: z.number().nullish(),
  total: z.number().nullish(),
  payment_rate_cost: z.number().nullish(),
  iva_cost: z.number().nullish(),
  container_cost: z.number().nullish(),
  difference_cost: z.number().nullish(),
  transport_cost: z.number().nullish(),
  transport_distance: z.number().nullish(),
  pick_up_dt: z.string().nullish(),
  delivery_dt: z.string().nullish(),
  payment_released_dt: z.string().nullish(),
  expiration_payment_dt: z.string().nullish(),
  expiration_transport_dt: z.string().nullish(),
  expiration_validation_dt: z.string().nullish(),
  create_dt: z.string(),
  update_dt: z.string(),
  commodity_weight: z.number().nullish(),
  final_weight: z.number().nullish(),
  difference_weight: z.number().nullish(),
  pick_up_commodity: z.boolean().optional(),
  active: z.boolean().optional(),
  is_validate_product: z.boolean().optional(),
  finished: z.boolean().optional(),
  polymorphic_ctype: z.number().int().nullable(),
});
export const PaginatedSaleProcessList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(SaleProcess),
  })
  .partial();
export const TokenObtainPair = z.object({
  username: z.string(),
  password: z.string(),
  access: z.string(),
  refresh: z.string(),
});
export const DisableAccountResponse = z.object({ detail: z.string() });
export const V2Login = z.object({
  email: z.string().email().optional(),
  password: z.string(),
});
export const V2User = z.object({
  id: z.number().int(),
  username: z
    .string()
    .max(150)
    .regex(/^[\w.@+-]+$/),
  email: z.string().max(254).email().nullish(),
  phone_number: z
    .string()
    .max(17)
    .regex(/^\+?1?\d{9,15}$/)
    .nullish(),
  first_name: z.string().max(150).optional(),
  last_name: z.string().max(150).optional(),
  registration_completed: z.boolean(),
  profile: z.string().nullish(),
  province: z.string().nullish(),
  interests: z.array(z.string()),
  marketing_offers_ok: z.boolean().optional(),
});
export const V2JWT = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  user: V2User,
});
export const V2PasswordReset = z.object({ email: z.string().email() });
export const PasswordResetResponse = z.object({ detail: z.string() });
export const V2Register = z.object({
  email: z.string().email(),
  phone_number: z.string(),
  password1: z.string(),
  password2: z.string(),
  legal_terms_ok: z.boolean(),
});
export const V2Social = z.object({ access_token: z.string() });
export const V2Profile = z.object({ name: z.string().max(150) });
export const PaginatedV2ProfileList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(V2Profile),
  })
  .partial();
export const V2Province = z.object({
  id: z.number().int(),
  name: z.string().max(255),
});
export const PaginatedV2ProvinceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(V2Province),
  })
  .partial();
export const PaginatedV2UserList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(V2User),
  })
  .partial();
export const PatchedV2User = z
  .object({
    id: z.number().int(),
    username: z
      .string()
      .max(150)
      .regex(/^[\w.@+-]+$/),
    email: z.string().max(254).email().nullable(),
    phone_number: z
      .string()
      .max(17)
      .regex(/^\+?1?\d{9,15}$/)
      .nullable(),
    first_name: z.string().max(150),
    last_name: z.string().max(150),
    registration_completed: z.boolean(),
    profile: z.string().nullable(),
    province: z.string().nullable(),
    interests: z.array(z.string()),
    marketing_offers_ok: z.boolean(),
  })
  .partial();
export const CallMeResponse = z.object({ detail: z.string() });
export const CallMeUnauthorizedResponse = z.object({ detail: z.string() });
export const AvailableProduct = z.object({
  id: z.number().int(),
  name: z.string(),
  variety: z.string(),
});
export const PaginatedAvailableProductList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(AvailableProduct),
  })
  .partial();
export const CreatorProfile = z.object({
  name: z.string().max(150),
  color: z.string().max(20).optional(),
});
export const MarketSaleInterest = z.object({
  id: z.number().int(),
  updated_at: z.string(),
  price: z.string().regex(/^-?\d{0,8}(?:\.\d{0,2})?$/),
  province: z.string(),
  creator_profile: CreatorProfile,
  product: Product,
});
export const PaginatedMarketSaleInterestList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(MarketSaleInterest),
  })
  .partial();
export const PurchaseInterestStateMetadataValueEnum = z.enum([
  'IN_PROGRESS',
  'CONTACTED',
  'CANCELLED',
  'UNAVAILABLE',
  'BOUGHT',
  'BOUGHT_AWAITING_LOAD',
  'BOUGHT_AWAITING_PAYMENT',
  'PAID',
]);
export const PurchaseInterestStateMetadata = z.object({
  value: PurchaseInterestStateMetadataValueEnum,
  label: z.string(),
  icon: z.string(),
  color: z.string(),
  canBeCancelled: z.boolean(),
});
export const ReadPurchaseInterest = z.object({
  id: z.number().int(),
  created_at: z.string(),
  updated_at: z.string(),
  bought_at: z.string().nullable(),
  price: z
    .string()
    .regex(/^-?\d{0,8}(?:\.\d{0,2})?$/)
    .optional(),
  province: z.string(),
  product: Product,
  state: PurchaseInterestStateMetadata,
});
export const PaginatedReadPurchaseInterestList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ReadPurchaseInterest),
  })
  .partial();
export const WritePurchaseInterest = z.object({
  interested_in: z.number().int(),
});
export const SaleInterestStateMetadataValueEnum = z.enum([
  'VALIDATION_PENDING',
  'LOOKING_FOR_BUYER',
  'CANCELLED',
  'NOT_VALIDATED',
  'EXPIRED',
  'SOLD',
  'SOLD_AWAITING_LOAD',
  'SOLD_AWAITING_PAYMENT',
  'CHARGED',
]);
export const SaleInterestStateMetadata = z.object({
  value: SaleInterestStateMetadataValueEnum,
  label: z.string(),
  icon: z.string(),
  color: z.string(),
  canBeCancelled: z.boolean(),
  canBeEdited: z.boolean(),
});
export const ReadSaleInterest = z.object({
  id: z.number().int(),
  created_at: z.string(),
  updated_at: z.string(),
  sold_at: z.string().nullable(),
  price: z.number(),
  amount: z.number().nullable(),
  province: z.string(),
  product: Product,
  state: SaleInterestStateMetadata,
});
export const PaginatedReadSaleInterestList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ReadSaleInterest),
  })
  .partial();
export const WriteSaleInterest = z.object({
  price: z.number(),
  amount: z.number().nullish(),
  province: z.string(),
  product: z.number().int(),
  created_from: z.number().int().nullish(),
});
export const PatchedWriteSaleInterest = z
  .object({
    price: z.number(),
    amount: z.number().nullable(),
    province: z.string(),
    product: z.number().int(),
    created_from: z.number().int().nullable(),
  })
  .partial();
export const Comment = z
  .object({
    title: z.string().max(255).nullable(),
    body: z.string().nullable(),
  })
  .partial();
export const V2MetaProduct = z.object({
  id: z.number().int(),
  name: z.string().max(150),
  icon: z.string().max(16).nullish(),
});
export const V2Family = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const V2Variety = z.object({
  id: z.number().int(),
  name: z.string().max(150),
});
export const V2Product = z.object({
  id: z.number().int(),
  meta_product: V2MetaProduct,
  family: V2Family,
  variety: V2Variety,
});
export const V2DataSource = z.object({
  id: z.number().int(),
  name: z.string().max(255),
  link: z.string().max(200).url().nullish(),
  kind: KindEnum.optional(),
});
export const V2DataPrice = z.object({
  id: z.number().int(),
  product: V2Product,
  province: V2Province,
  data_source: V2DataSource,
  last_deviation: z
    .object({ date: z.string(), deviation: z.number() })
    .nullable(),
  date: z.string(),
  price: z.number(),
  quantity: z.number().nullish(),
  region: z.number().int().nullish(),
});
export const PaginatedV2DataPriceList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(V2DataPrice),
  })
  .partial();
export const DataPriceHistory = z.object({
  date: z.string(),
  price: z.number(),
  deviation: z.number().nullable(),
});
export const PaginatedDataPriceHistoryList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DataPriceHistory),
  })
  .partial();
export const DataPriceRAG = z.object({
  id: z.number().int(),
  product: V2Product,
  date: z.string(),
  price: z.number(),
  data_source: V2DataSource,
  quantity: z.number().nullish(),
  province: V2Province,
  region: z.number().int().nullish(),
});
export const PaginatedDataPriceRAGList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DataPriceRAG),
  })
  .partial();
export const DataSourceRAG = z.object({
  id: z.number().int(),
  name: z.string().max(255),
  link: z.string().max(200).url().nullish(),
  kind: KindEnum.optional(),
});
export const PaginatedDataSourceRAGList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(DataSourceRAG),
  })
  .partial();
export const ProvinceRAG = z.object({
  id: z.number().int(),
  name: z.string().max(255),
});
export const PaginatedProvinceRAGList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(ProvinceRAG),
  })
  .partial();
export const RegionRAG = z.object({
  id: z.number().int(),
  name: z.string().max(255),
});
export const PaginatedRegionRAGList = z
  .object({
    count: z.number().int(),
    next: z.string().url().nullable(),
    previous: z.string().url().nullable(),
    results: z.array(RegionRAG),
  })
  .partial();
export const DeviceTokenInput = z.object({
  token: z.string().max(255),
  device_model: z.string().max(255).nullish(),
  device_os: z.string().max(255).nullish(),
});

const endpoints = makeApi([
  {
    method: 'get',
    path: '/api/v1/advertising/advertisement/',
    alias: 'advertisingAdvertisementList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'placement',
        type: 'Query',
        schema: z.enum(['DP', 'SI']).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
    ],
    response: z.array(Advertisement),
  },
  {
    method: 'get',
    path: '/api/v1/alerts/alerts/',
    alias: 'alertsAlertsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'condition',
        type: 'Query',
        schema: z.enum(['AE', 'GT', 'LT']).optional(),
      },
      {
        name: 'data_source_id',
        type: 'Query',
        schema: z.number().int().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'product_id',
        type: 'Query',
        schema: z.number().int().nullish(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().int().nullish(),
      },
    ],
    response: PaginatedAlertList,
  },
  {
    method: 'post',
    path: '/api/v1/alerts/alerts/',
    alias: 'alertsAlertsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Alert,
      },
    ],
    response: Alert,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/alerts/:id/',
    alias: 'alertsAlertsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Alert,
  },
  {
    method: 'patch',
    path: '/api/v1/alerts/alerts/:id/',
    alias: 'alertsAlertsPartialUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedAlert,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Alert,
  },
  {
    method: 'delete',
    path: '/api/v1/alerts/alerts/:id/',
    alias: 'alertsAlertsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/alerts/emails/contact/',
    alias: 'alertsEmailsContactCreate',
    description: `Send email fields:
    -
    - Phone number
    - Subject
    - Message`,
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/',
    alias: 'alertsIssuesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'comment',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'create_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'description',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'other_subject_option',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'process__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'solved',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'solved_comment',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'subject_option__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'subject_option__subject__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'subject_option__subject__name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'subject_option__title',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'title',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'type__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'type__name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'update_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedIssueList,
  },
  {
    method: 'post',
    path: '/api/v1/alerts/issues/',
    alias: 'alertsIssuesCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Issue,
      },
    ],
    response: Issue,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/:id/',
    alias: 'alertsIssuesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Issue,
  },
  {
    method: 'put',
    path: '/api/v1/alerts/issues/:id/',
    alias: 'alertsIssuesUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Issue,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Issue,
  },
  {
    method: 'delete',
    path: '/api/v1/alerts/issues/:id/',
    alias: 'alertsIssuesDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/options/',
    alias: 'alertsIssuesOptionsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'subject__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'subject__name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'title',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedIssueSubjectOptionList,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/options/:id/',
    alias: 'alertsIssuesOptionsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: IssueSubjectOption,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/subjects/',
    alias: 'alertsIssuesSubjectsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedIssueSubjectList,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/subjects/:id/',
    alias: 'alertsIssuesSubjectsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: IssueSubject,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/types/',
    alias: 'alertsIssuesTypesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedIssueTypeList,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/issues/types/:id/',
    alias: 'alertsIssuesTypesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: IssueType,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/notifications/',
    alias: 'alertsNotificationsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'create_dt_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'create_dt_before',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'read',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'status',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedNotificationList,
  },
  {
    method: 'get',
    path: '/api/v1/alerts/notifications/:id/',
    alias: 'alertsNotificationsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Notification,
  },
  {
    method: 'patch',
    path: '/api/v1/alerts/notifications/:id/',
    alias: 'alertsNotificationsPartialUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedNotification,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Notification,
  },
  {
    method: 'post',
    path: '/api/v1/alerts/notifications/all/mark_seen',
    alias: 'alertsNotificationsAllMarkSeenCreate',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/auth/login/',
    alias: 'authLoginCreate',
    description: `Check the credentials and return the REST Token
if the credentials are valid and authenticated.
Calls Django Auth login method to register User ID
in Django session framework

Accept the following POST parameters: username, password
Return the REST Framework Token Object&#x27;s key.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Login,
      },
    ],
    response: JWT,
  },
  {
    method: 'get',
    path: '/api/v1/auth/logout/',
    alias: 'authLogoutRetrieve',
    description: `Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.`,
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/logout/',
    alias: 'authLogoutCreate',
    description: `Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.`,
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/password/change/',
    alias: 'authPasswordChangeCreate',
    description: `Calls Django Auth SetPasswordForm save method.

Accepts the following POST parameters: new_password1, new_password2
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PasswordChange,
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/password/reset/',
    alias: 'authPasswordResetCreate',
    description: `Calls Django Auth PasswordResetForm save method.

Accepts the following POST parameters: email
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }),
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/password/reset/confirm/',
    alias: 'authPasswordResetConfirmCreate',
    description: `Password reset e-mail link is confirmed, therefore
this resets the user&#x27;s password.

Accepts the following POST parameters: token, uid,
    new_password1, new_password2
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PasswordResetConfirm,
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/registration/',
    alias: 'authRegistrationCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RegisterUser,
      },
    ],
    response: JWT,
  },
  {
    method: 'post',
    path: '/api/v1/auth/registration/verify-email/',
    alias: 'authRegistrationVerifyEmailCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ key: z.string() }),
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v1/auth/token/refresh/',
    alias: 'authTokenRefreshCreate',
    description: `Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TokenRefresh,
      },
    ],
    response: TokenRefresh,
  },
  {
    method: 'post',
    path: '/api/v1/auth/token/verify/',
    alias: 'authTokenVerifyCreate',
    description: `Takes a token and indicates if it is valid.  This view provides no
information about a token&#x27;s fitness for a particular use.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ token: z.string() }),
      },
    ],
    response: z.object({ token: z.string() }),
  },
  {
    method: 'get',
    path: '/api/v1/auth/user/',
    alias: 'authUserRetrieve',
    description: `Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.`,
    requestFormat: 'json',
    response: User,
  },
  {
    method: 'put',
    path: '/api/v1/auth/user/',
    alias: 'authUserUpdate',
    description: `Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: User,
      },
    ],
    response: User,
  },
  {
    method: 'patch',
    path: '/api/v1/auth/user/',
    alias: 'authUserPartialUpdate',
    description: `Reads and updates UserModel fields
Accepts GET, PUT, PATCH methods.

Default accepted fields: username, first_name, last_name
Default display fields: pk, username, email, first_name, last_name
Read-only fields: pk, email

Returns UserModel fields.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedUser,
      },
    ],
    response: User,
  },
  {
    method: 'get',
    path: '/api/v1/core/address/',
    alias: 'coreAddressList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedAddressList,
  },
  {
    method: 'post',
    path: '/api/v1/core/address/',
    alias: 'coreAddressCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Address,
      },
    ],
    response: Address,
  },
  {
    method: 'get',
    path: '/api/v1/core/address/:id/',
    alias: 'coreAddressRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Address,
  },
  {
    method: 'put',
    path: '/api/v1/core/address/:id/',
    alias: 'coreAddressUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Address,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Address,
  },
  {
    method: 'delete',
    path: '/api/v1/core/address/:id/',
    alias: 'coreAddressDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/core/profiles/',
    alias: 'coreProfilesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedProfileList,
  },
  {
    method: 'get',
    path: '/api/v1/core/profiles/:id/',
    alias: 'coreProfilesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Profile,
  },
  {
    method: 'get',
    path: '/api/v1/core/users/',
    alias: 'coreUsersList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedUserList,
  },
  {
    method: 'get',
    path: '/api/v1/core/users/:id/',
    alias: 'coreUsersRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: User,
  },
  {
    method: 'put',
    path: '/api/v1/core/users/:id/',
    alias: 'coreUsersUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: User,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: User,
  },
  {
    method: 'delete',
    path: '/api/v1/core/users/:id/',
    alias: 'coreUsersDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/legacy/faqs/',
    alias: 'legacyFaqsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedQuestionList,
  },
  {
    method: 'get',
    path: '/api/v1/legacy/faqs/:id/',
    alias: 'legacyFaqsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Question,
  },
  {
    method: 'get',
    path: '/api/v1/legacy/terms/',
    alias: 'legacyTermsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedTermList,
  },
  {
    method: 'get',
    path: '/api/v1/legacy/terms/:id/',
    alias: 'legacyTermsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Term,
  },
  {
    method: 'get',
    path: '/api/v1/legacy/terms/base/',
    alias: 'legacyTermsBaseList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedTermBaseList,
  },
  {
    method: 'get',
    path: '/api/v1/legacy/terms/base/:id/',
    alias: 'legacyTermsBaseRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: TermBase,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/data-prices/',
    alias: 'marketdataAdminDataPricesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'data_source_kind',
        type: 'Query',
        schema: z.enum(['ABASTORES', 'INTERNATIONAL', 'NATIONAL']).optional(),
      },
      {
        name: 'date_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_before',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z
          .array(z.enum(['Provincia (A - Z)', 'Provincia (Z - A)']))
          .optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'region',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedAdminDataPriceList,
  },
  {
    method: 'post',
    path: '/api/v1/marketdata/admin/data-prices/',
    alias: 'marketdataAdminDataPricesCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AdminDataPrice,
      },
    ],
    response: AdminDataPrice,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/data-prices/:id/',
    alias: 'marketdataAdminDataPricesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: AdminDataPrice,
  },
  {
    method: 'patch',
    path: '/api/v1/marketdata/admin/data-prices/:id/',
    alias: 'marketdataAdminDataPricesPartialUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedAdminDataPrice,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: AdminDataPrice,
  },
  {
    method: 'delete',
    path: '/api/v1/marketdata/admin/data-prices/:id/',
    alias: 'marketdataAdminDataPricesDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/data-sources/',
    alias: 'marketdataAdminDataSourcesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'link',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedDataSourceList,
  },
  {
    method: 'post',
    path: '/api/v1/marketdata/admin/data-sources/',
    alias: 'marketdataAdminDataSourcesCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DataSource,
      },
    ],
    response: DataSource,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/data-sources/:id/',
    alias: 'marketdataAdminDataSourcesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: DataSource,
  },
  {
    method: 'patch',
    path: '/api/v1/marketdata/admin/data-sources/:id/',
    alias: 'marketdataAdminDataSourcesPartialUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedDataSource,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: DataSource,
  },
  {
    method: 'delete',
    path: '/api/v1/marketdata/admin/data-sources/:id/',
    alias: 'marketdataAdminDataSourcesDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/provinces/',
    alias: 'marketdataAdminProvincesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedProvinceList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/provinces/:id/',
    alias: 'marketdataAdminProvincesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Province,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/regions/',
    alias: 'marketdataAdminRegionsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedRegionList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/admin/regions/:id/',
    alias: 'marketdataAdminRegionsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Region,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/data-deviations/products/',
    alias: 'marketdataDataDeviationsProductsList',
    description: 'Return a dict of data deviations for a product.',
    requestFormat: 'json',
    response: z.array(GroupDataDeviation),
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/data-prices/',
    alias: 'marketdataDataPricesList',
    description: 'For performance reasons, we cache the response for 5 minutes.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'data_source_kind',
        type: 'Query',
        schema: z.enum(['ABASTORES', 'INTERNATIONAL', 'NATIONAL']).optional(),
      },
      {
        name: 'date_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_before',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z
          .array(z.enum(['Provincia (A - Z)', 'Provincia (Z - A)']))
          .optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'region',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedDataPriceList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/data-prices/history/',
    alias: 'marketdataDataPricesHistoryList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'data_source_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'from_date',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'to_date',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedLeanDataPriceList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/data-prices/products/',
    alias: 'marketdataDataPricesProductsList',
    description: 'Return a dict of data prices for a product.',
    requestFormat: 'json',
    response: z.array(GroupDataPrice),
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/data-prices/search/',
    alias: 'marketdataDataPricesSearchList',
    description: `For performance reasons, we cache the response for an hour.
Ensure that the query param &quot;q&quot; is set, otherwise we return an error.
Ensure that the query param &quot;q&quot; is at least 3 characters long, otherwise we return an error.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedDataPriceList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/provinces/',
    alias: 'marketdataProvincesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedProvinceList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/provinces/:id/',
    alias: 'marketdataProvincesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Province,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/regions/',
    alias: 'marketdataRegionsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedRegionList,
  },
  {
    method: 'get',
    path: '/api/v1/marketdata/regions/:id/',
    alias: 'marketdataRegionsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Region,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/auctionref/:id/',
    alias: 'marketplaceAuctionrefUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AuctionSerializerRef,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: AuctionSerializerRef,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/auctions/',
    alias: 'marketplaceAuctionsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'abastores_certificate',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'bid_user',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'certificates',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'city',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'containers',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'country_code',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'expirated_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'has_samples',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'has_winner_or_not_expired',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_expired',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_old',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_winners',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'max_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'max_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'min_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'min_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.array(z.enum(['-expirate_dt', 'expirate_dt'])).optional(),
      },
      {
        name: 'user',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'uses',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'want_certificate',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: PaginatedAuctionSerializerListList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/auctions/',
    alias: 'marketplaceAuctionsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Auction,
      },
    ],
    response: Auction,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/auctions/:id/',
    alias: 'marketplaceAuctionsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Auction,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/auctions/:id/',
    alias: 'marketplaceAuctionsUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Auction,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Auction,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/auctions/:id/',
    alias: 'marketplaceAuctionsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/bids/',
    alias: 'marketplaceBidsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'auction__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'auction__init_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'auction__min_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'auction__quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'distance',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedBidList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/bids/',
    alias: 'marketplaceBidsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Bid,
      },
    ],
    response: Bid,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/bids/:id/',
    alias: 'marketplaceBidsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Bid,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/bids/:id/',
    alias: 'marketplaceBidsUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Bid,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Bid,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/certificates/',
    alias: 'marketplaceCertificatesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedCertificateList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/certificates/:id/',
    alias: 'marketplaceCertificatesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Certificate,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/certifications/',
    alias: 'marketplaceCertificationsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'auction',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'auction__user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'certification_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'create_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'is_active',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_paid',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_send',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'iva',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offer',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offer__user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'reference',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'subtotal',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'total',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'update_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedCertificationList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/certifications/',
    alias: 'marketplaceCertificationsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Certification,
      },
    ],
    response: Certification,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/certifications/:reference/',
    alias: 'marketplaceCertificationsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'reference',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: Certification,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/certifications/:reference/',
    alias: 'marketplaceCertificationsUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Certification,
      },
      {
        name: 'reference',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: Certification,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/certifications/:reference/',
    alias: 'marketplaceCertificationsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'reference',
        type: 'Path',
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/containers/',
    alias: 'marketplaceContainersList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedContainerList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/containers/',
    alias: 'marketplaceContainersCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Container,
      },
    ],
    response: Container,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/containers/:id/',
    alias: 'marketplaceContainersRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Container,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/containers/:id/',
    alias: 'marketplaceContainersUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Container,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Container,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/containers/:id/',
    alias: 'marketplaceContainersDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/demands/',
    alias: 'marketplaceDemandsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'abastores_certificate',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'certificates',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'city',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'containers',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'country_code',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'expirated_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'has_samples',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_expired',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_old',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'is_winners',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'max_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'max_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'min_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'min_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'offer_user',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'order',
        type: 'Query',
        schema: z.array(z.enum(['-expirate_dt', 'expirate_dt'])).optional(),
      },
      {
        name: 'user',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'uses',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'want_certificate',
        type: 'Query',
        schema: z.boolean().optional(),
      },
    ],
    response: PaginatedDemandSerializerListList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/demands/',
    alias: 'marketplaceDemandsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Demand,
      },
    ],
    response: Demand,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/demands/:id/',
    alias: 'marketplaceDemandsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Demand,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/demands/:id/',
    alias: 'marketplaceDemandsUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Demand,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Demand,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/demands/:id/',
    alias: 'marketplaceDemandsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/demandsref/:id/',
    alias: 'marketplaceDemandsrefUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DemandSerializerRef,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: DemandSerializerRef,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/families-rag/',
    alias: 'marketplaceFamiliesRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedFamilyList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/families/',
    alias: 'marketplaceFamiliesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedFamilyList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/families/:id/',
    alias: 'marketplaceFamiliesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Family,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/follows/',
    alias: 'marketplaceFollowsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedFollowList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/follows/',
    alias: 'marketplaceFollowsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Follow,
      },
    ],
    response: Follow,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/follows/:id/',
    alias: 'marketplaceFollowsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Follow,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/follows/:id/',
    alias: 'marketplaceFollowsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/meta_products-rag/',
    alias: 'marketplaceMetaProductsRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedMetaProductList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/meta_products/',
    alias: 'marketplaceMetaProductsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedMetaProductList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/meta_products/:id/',
    alias: 'marketplaceMetaProductsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: MetaProduct,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/offers/',
    alias: 'marketplaceOffersList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'demand__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'demand__init_price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'demand__min_quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'demand__quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'distance',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedOfferList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/offers/',
    alias: 'marketplaceOffersCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Offer,
      },
    ],
    response: Offer,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/offers/:id/',
    alias: 'marketplaceOffersRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Offer,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/offers/:id/',
    alias: 'marketplaceOffersUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Offer,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Offer,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/packs/',
    alias: 'marketplacePacksList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedPackList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/packs/:id/',
    alias: 'marketplacePacksRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Pack,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/parameter_templates/',
    alias: 'marketplaceParameterTemplatesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'max_range',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'min_range',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'unit',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedParameterTemplateList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/parameter_templates/:id/',
    alias: 'marketplaceParameterTemplatesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: ParameterTemplate,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/parameters/',
    alias: 'marketplaceParametersList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'base_market_action',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'parameter_template',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'value',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedParameterList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/parameters/',
    alias: 'marketplaceParametersCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Parameter,
      },
    ],
    response: Parameter,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/parameters/:id/',
    alias: 'marketplaceParametersRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Parameter,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/parameters/:id/',
    alias: 'marketplaceParametersUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Parameter,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Parameter,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/products-rag/',
    alias: 'marketplaceProductsRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedProductList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/products/',
    alias: 'marketplaceProductsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedProductList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/products/',
    alias: 'marketplaceProductsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Product,
      },
    ],
    response: Product,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/products/:id/',
    alias: 'marketplaceProductsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Product,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/ratings/',
    alias: 'marketplaceRatingsList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'create_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'quality',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'response_rate',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'service',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'user_rated',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'user_rating',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedRatingList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/ratings/',
    alias: 'marketplaceRatingsCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Rating,
      },
    ],
    response: Rating,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/ratings/:id/',
    alias: 'marketplaceRatingsRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Rating,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/ratings/:id/',
    alias: 'marketplaceRatingsUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Rating,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Rating,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/ratings/:id/',
    alias: 'marketplaceRatingsDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/samples/',
    alias: 'marketplaceSamplesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'aprox_shipping_date',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'base_market_action__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'create_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'is_send',
        type: 'Query',
        schema: z.boolean().optional(),
      },
      {
        name: 'iva',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'quantity',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'reference',
        type: 'Query',
        schema: z.string().uuid().optional(),
      },
      {
        name: 'shipping_company__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'subtotal',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'total',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'update_dt',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'user__id',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedSampleList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/samples/',
    alias: 'marketplaceSamplesCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Sample,
      },
    ],
    response: Sample,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/samples/:id/',
    alias: 'marketplaceSamplesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Sample,
  },
  {
    method: 'put',
    path: '/api/v1/marketplace/samples/:id/',
    alias: 'marketplaceSamplesUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Sample,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Sample,
  },
  {
    method: 'delete',
    path: '/api/v1/marketplace/samples/:id/',
    alias: 'marketplaceSamplesDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/shipping_companies/',
    alias: 'marketplaceShippingCompaniesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'package_type',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedShippingCompanyList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/shipping_companies/:id/',
    alias: 'marketplaceShippingCompaniesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: ShippingCompany,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/uses/',
    alias: 'marketplaceUsesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedUseList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/uses/:id/',
    alias: 'marketplaceUsesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Use,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/varieties-rag/',
    alias: 'marketplaceVarietiesRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedVarietyList,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/varieties/',
    alias: 'marketplaceVarietiesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedVarietyList,
  },
  {
    method: 'post',
    path: '/api/v1/marketplace/varieties/',
    alias: 'marketplaceVarietiesCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: Variety,
      },
    ],
    response: Variety,
  },
  {
    method: 'get',
    path: '/api/v1/marketplace/varieties/:id/',
    alias: 'marketplaceVarietiesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: Variety,
  },
  {
    method: 'get',
    path: '/api/v1/payments/accounts/:id/',
    alias: 'paymentsAccountsRetrieve',
    description: 'Payment Stripe Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/v1/payments/accounts/:id/',
    alias: 'paymentsAccountsUpdate',
    description: 'Payment Stripe Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/v1/payments/accounts/:id/',
    alias: 'paymentsAccountsDestroy',
    description: 'Payment Stripe Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/payments/accounts/external_accounts/',
    alias: 'paymentsAccountsExternalAccountsCreate',
    description: 'Payment Stripe External Account Information',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/accounts/external_accounts/:id/',
    alias: 'paymentsAccountsExternalAccountsRetrieve',
    description: 'Payment Stripe External Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'put',
    path: '/api/v1/payments/accounts/external_accounts/:id/',
    alias: 'paymentsAccountsExternalAccountsUpdate',
    description: 'Payment Stripe External Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'delete',
    path: '/api/v1/payments/accounts/external_accounts/:id/',
    alias: 'paymentsAccountsExternalAccountsDestroy',
    description: 'Payment Stripe External Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/accounts/persons/:id/',
    alias: 'paymentsAccountsPersonsRetrieve',
    description: 'Payment Stripe Person Account Information',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/payments/intents/',
    alias: 'paymentsIntentsCreate',
    description: 'Payment Intent Stripe',
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/intents/:id/',
    alias: 'paymentsIntentsRetrieve',
    description: 'Payment Intent Stripe',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/process/buys/',
    alias: 'paymentsProcessBuysList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedBuyProcessList,
  },
  {
    method: 'get',
    path: '/api/v1/payments/process/buys/:id/',
    alias: 'paymentsProcessBuysRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: BuyProcess,
  },
  {
    method: 'put',
    path: '/api/v1/payments/process/buys/:id/',
    alias: 'paymentsProcessBuysUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BuyProcess,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: BuyProcess,
  },
  {
    method: 'delete',
    path: '/api/v1/payments/process/buys/:id/',
    alias: 'paymentsProcessBuysDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/process/sales/',
    alias: 'paymentsProcessSalesList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedSaleProcessList,
  },
  {
    method: 'get',
    path: '/api/v1/payments/process/sales/:id/',
    alias: 'paymentsProcessSalesRetrieve',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: SaleProcess,
  },
  {
    method: 'put',
    path: '/api/v1/payments/process/sales/:id/',
    alias: 'paymentsProcessSalesUpdate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: SaleProcess,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: SaleProcess,
  },
  {
    method: 'delete',
    path: '/api/v1/payments/process/sales/:id/',
    alias: 'paymentsProcessSalesDestroy',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/stripe/reauth/:accountId/',
    alias: 'paymentsStripeReauthRetrieve',
    description: `Stripe Reauth.
--------------
Query Parameters:
    - &#x60;account_id&#x60;  Stripe Account ID

The URL that the user will be redirected to if the account link is no longer valid.
Your refresh_url should trigger a method on your server to create a new account link using this API,
with the same parameters, and redirect the user to the new account link.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'accountId',
        type: 'Path',
        schema: z.string().regex(/^[\w\-]+$/),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v1/payments/stripe/return/',
    alias: 'paymentsStripeReturnRetrieve',
    description: `Stripe Return.
--------------
The URL that the user will be redirected to upon leaving or completing the linked flow.`,
    requestFormat: 'json',
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/payments/stripe/verify/identity/:accountId/',
    alias: 'paymentsStripeVerifyIdentityCreate',
    description: `Stripe Verify Identity.
------------------------
https://stripe.com/docs/connect/identity-verification-api
https://stripe.com/docs/connect/identity-verification-api#uploading-a-file`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'accountId',
        type: 'Path',
        schema: z.string().regex(/^[\w\-]+$/),
      },
    ],
    response: z.void(),
  },
  {
    method: 'post',
    path: '/api/v1/token/',
    alias: 'tokenCreate',
    description: `Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TokenObtainPair,
      },
    ],
    response: TokenObtainPair,
  },
  {
    method: 'post',
    path: '/api/v1/token/refresh/',
    alias: 'tokenRefreshCreate',
    description: `Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TokenRefresh,
      },
    ],
    response: TokenRefresh,
  },
  {
    method: 'post',
    path: '/api/v1/token/verify/',
    alias: 'tokenVerifyCreate',
    description: `Takes a token and indicates if it is valid.  This view provides no
information about a token&#x27;s fitness for a particular use.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ token: z.string() }),
      },
    ],
    response: z.object({ token: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/account/disable/',
    alias: 'authAccountDisableCreate',
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/login/',
    alias: 'authLoginCreate2',
    description: `Check the credentials and return the REST Token
if the credentials are valid and authenticated.
Calls Django Auth login method to register User ID
in Django session framework

Accept the following POST parameters: username, password
Return the REST Framework Token Object&#x27;s key.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: V2Login,
      },
    ],
    response: V2JWT,
  },
  {
    method: 'get',
    path: '/api/v2/auth/logout/',
    alias: 'authLogoutRetrieve2',
    description: `Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.`,
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/logout/',
    alias: 'authLogoutCreate2',
    description: `Calls Django logout method and delete the Token object
assigned to the current User object.

Accepts/Returns nothing.`,
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/password/change',
    alias: 'authPasswordChangeCreate2',
    description: `Calls Django Auth SetPasswordForm save method.

Accepts the following POST parameters: new_password1, new_password2
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PasswordChange,
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/password/reset',
    alias: 'authPasswordResetCreate2',
    description: `Calls Django Auth PasswordResetForm save method.

Accepts the following POST parameters: email
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ email: z.string().email() }),
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/password/reset/confirm',
    alias: 'authPasswordResetConfirmCreate2',
    description: `Password reset e-mail link is confirmed, therefore
this resets the user&#x27;s password.

Accepts the following POST parameters: token, uid,
    new_password1, new_password2
Returns the success/fail message.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PasswordResetConfirm,
      },
    ],
    response: z.object({ detail: z.string() }),
  },
  {
    method: 'post',
    path: '/api/v2/auth/registration/',
    alias: 'authRegistrationCreate2',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: V2Register,
      },
    ],
    response: V2JWT,
  },
  {
    method: 'post',
    path: '/api/v2/auth/social',
    alias: 'authSocialCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ access_token: z.string() }),
      },
    ],
    response: V2JWT,
  },
  {
    method: 'post',
    path: '/api/v2/auth/token/refresh/',
    alias: 'authTokenRefreshCreate2',
    description: `Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: TokenRefresh,
      },
    ],
    response: TokenRefresh,
  },
  {
    method: 'post',
    path: '/api/v2/auth/token/verify/',
    alias: 'authTokenVerifyCreate2',
    description: `Takes a token and indicates if it is valid.  This view provides no
information about a token&#x27;s fitness for a particular use.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ token: z.string() }),
      },
    ],
    response: z.object({ token: z.string() }),
  },
  {
    method: 'get',
    path: '/api/v2/core/profiles/',
    alias: 'coreProfilesList2',
    description: 'This queryset returns a paginated list of all profiles.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'q',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedV2ProfileList,
  },
  {
    method: 'get',
    path: '/api/v2/core/provinces/',
    alias: 'coreProvincesList',
    description: 'This queryset returns a paginated list of all provinces.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'q',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedV2ProvinceList,
  },
  {
    method: 'get',
    path: '/api/v2/core/users/',
    alias: 'coreUsersList2',
    description: 'This queryset will only allow a user to update their own profile.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedV2UserList,
  },
  {
    method: 'get',
    path: '/api/v2/core/users/:id/',
    alias: 'coreUsersRetrieve2',
    description: 'This queryset will only allow a user to update their own profile.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: V2User,
  },
  {
    method: 'patch',
    path: '/api/v2/core/users/:id/',
    alias: 'coreUsersPartialUpdate',
    description: 'This queryset will only allow a user to update their own profile.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedV2User,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: V2User,
  },
  {
    method: 'post',
    path: '/api/v2/help/call-me/',
    alias: 'helpCallMeCreate',
    requestFormat: 'json',
    response: z.object({ detail: z.string() }),
    errors: [
      {
        status: 401,
        schema: z.object({ detail: z.string() }),
      },
    ],
  },
  {
    method: 'get',
    path: '/api/v2/interests/available-products/',
    alias: 'interestsAvailableProductsList',
    description: `This endpoint returns a searchable paginated list of
products, but only their meta_product name, variety name and id.
Ordered by meta_product name and variety name.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'q',
        type: 'Query',
        schema: z.string().optional(),
      },
    ],
    response: PaginatedAvailableProductList,
  },
  {
    method: 'get',
    path: '/api/v2/interests/market/',
    alias: 'interestsMarketList',
    description: 'This endpoint returns SaleInterests that are in the state &quot;looking for buyer&quot;.',
    requestFormat: 'json',
    parameters: [
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z.array(z.enum(['Última actualización (Desc)'])).optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedMarketSaleInterestList,
  },
  {
    method: 'get',
    path: '/api/v2/interests/purchase/',
    alias: 'interestsPurchaseList',
    description: `This endpoint returns my PurchaseInterests.
You can also &quot;delete&quot; them.
Deleting a PurchaseInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z
          .array(
            z.enum([
              'Precio (Asc)',
              'Precio (Desc)',
              'Última actualización (Asc)',
              'Última actualización (Desc)',
              'Última creación (Asc)',
              'Última creación (Desc)',
              'Última venta (Asc)',
              'Última venta (Desc)',
            ])
          )
          .optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'state',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedReadPurchaseInterestList,
  },
  {
    method: 'post',
    path: '/api/v2/interests/purchase/',
    alias: 'interestsPurchaseCreate',
    description: `This endpoint returns my PurchaseInterests.
You can also &quot;delete&quot; them.
Deleting a PurchaseInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({ interested_in: z.number().int() }),
      },
    ],
    response: z.object({ interested_in: z.number().int() }),
  },
  {
    method: 'delete',
    path: '/api/v2/interests/purchase/:id/',
    alias: 'interestsPurchaseDestroy',
    description: `This endpoint returns my PurchaseInterests.
You can also &quot;delete&quot; them.
Deleting a PurchaseInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v2/interests/sale/',
    alias: 'interestsSaleList',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z
          .array(
            z.enum([
              'Precio (Asc)',
              'Precio (Desc)',
              'Última actualización (Asc)',
              'Última actualización (Desc)',
              'Última creación (Asc)',
              'Última creación (Desc)',
              'Última venta (Asc)',
              'Última venta (Desc)',
            ])
          )
          .optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'state',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedReadSaleInterestList,
  },
  {
    method: 'post',
    path: '/api/v2/interests/sale/',
    alias: 'interestsSaleCreate',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WriteSaleInterest,
      },
    ],
    response: WriteSaleInterest,
  },
  {
    method: 'get',
    path: '/api/v2/interests/sale/:id/',
    alias: 'interestsSaleRetrieve',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: ReadSaleInterest,
  },
  {
    method: 'put',
    path: '/api/v2/interests/sale/:id/',
    alias: 'interestsSaleUpdate',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: WriteSaleInterest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: WriteSaleInterest,
  },
  {
    method: 'patch',
    path: '/api/v2/interests/sale/:id/',
    alias: 'interestsSalePartialUpdate',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: PatchedWriteSaleInterest,
      },
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: WriteSaleInterest,
  },
  {
    method: 'delete',
    path: '/api/v2/interests/sale/:id/',
    alias: 'interestsSaleDestroy',
    description: `This endpoint returns my SaleInterests.
You can also create, update and &quot;delete&quot; them.
Deleting a SaleInterest is actually cancelling it.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().int(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/comment/',
    alias: 'marketdataCommentList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'placement',
        type: 'Query',
        schema: z.enum(['DP', 'SI']).optional(),
      },
    ],
    response: z.array(Comment),
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/dataprice-history/',
    alias: 'marketdataDatapriceHistoryList',
    description: `This viewset returns the last N number of DataPrice entries for a given
combination of product, data_source, province and region.`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'data_source_id',
        type: 'Query',
        schema: z.number(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'product_id',
        type: 'Query',
        schema: z.number(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number(),
      },
      {
        name: 'region_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedDataPriceHistoryList,
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/dataprice-rag/',
    alias: 'marketdataDatapriceRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'date__gte',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date__lte',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_before',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'kind',
        type: 'Query',
        schema: z.enum(['ABASTORES', 'INTERNATIONAL', 'NATIONAL']).optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z.array(z.enum(['Fecha (Desc)'])).optional(),
      },
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'products_ids',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'provinces',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'provinces_ids',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedDataPriceRAGList,
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/dataprice/',
    alias: 'marketdataDatapriceList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'date__gte',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date__lte',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_after',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'date_before',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'family_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'kind',
        type: 'Query',
        schema: z.enum(['ABASTORES', 'INTERNATIONAL', 'NATIONAL']).optional(),
      },
      {
        name: 'meta_product',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'meta_product_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'o',
        type: 'Query',
        schema: z.array(z.enum(['Fecha (Desc)'])).optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'price',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'products',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'products_ids',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'province',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'province_id',
        type: 'Query',
        schema: z.number().optional(),
      },
      {
        name: 'provinces',
        type: 'Query',
        schema: z.array(z.string()).optional(),
      },
      {
        name: 'provinces_ids',
        type: 'Query',
        schema: z.array(z.number()).optional(),
      },
      {
        name: 'variety',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'variety_id',
        type: 'Query',
        schema: z.number().optional(),
      },
    ],
    response: PaginatedV2DataPriceList,
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/datasource-rag/',
    alias: 'marketdataDatasourceRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedDataSourceRAGList,
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/province-rag/',
    alias: 'marketdataProvinceRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedProvinceRAGList,
  },
  {
    method: 'get',
    path: '/api/v2/marketdata/region-rag/',
    alias: 'marketdataRegionRagList',
    requestFormat: 'json',
    parameters: [
      {
        name: 'ordering',
        type: 'Query',
        schema: z.string().optional(),
      },
      {
        name: 'page',
        type: 'Query',
        schema: z.number().int().optional(),
      },
      {
        name: 'page_size',
        type: 'Query',
        schema: z.number().int().optional(),
      },
    ],
    response: PaginatedRegionRAGList,
  },
  {
    method: 'post',
    path: '/api/v2/notifications/device-token/',
    alias: 'notificationsDeviceTokenCreate',
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: DeviceTokenInput,
      },
    ],
    response: DeviceTokenInput,
  },
]);

export const api = new Zodios(endpoints, {
  axiosInstance: axios,
});
