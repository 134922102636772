<template>
  <a-theme :theme="theme">
    <router-view class="theme" />
  </a-theme>
</template>

<script setup lang="ts">
import 'uno.css';
import { defaultTheme, greenTheme } from './components/layoutElements/themes';
import { tryPromoteAppNotification } from 'src/components/notify/promoteAppNotification';
import { useAuthStore } from 'src/stores/auth';
import { Capacitor } from '@capacitor/core';

const route = useRoute();
const theme = computed(() =>
  route.name?.toString().startsWith('market') ? greenTheme : defaultTheme
);
const authStore = useAuthStore();

onMounted(() => {
  const isOnWebBrowser = Capacitor.getPlatform() === 'web';
  const isLogged = authStore.isLoggedIn;

  if (isOnWebBrowser && isLogged) {
    tryPromoteAppNotification();
  }
});
</script>
