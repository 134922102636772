import { promisifyDialog } from 'src/util/promisifyDialog';
import { Dialog } from 'quasar';
import AGenericDialog from './AGenericDialog.vue';
import { RouteLocation } from 'vue-router';

// **ONLY** for use in components

export const MESSAGES = {
  warehouse:
    'Mi almacén es tu apartado personal y necesitas tener una cuenta para entrar',
  new_sale_interest: 'Para crear la oferta necesitas tener una cuenta',
} as const;

export function anonRedirectToAuth(
  messageKey: keyof typeof MESSAGES,
  to?: string
) {
  return promisifyDialog(
    Dialog.create({
      component: (
        <AGenericDialog
          no-route-dismiss
          title="Sólo un paso más"
          text={MESSAGES[messageKey]}
          buttonLabel="Únete para continuar"
          buttonIcon="account_circle"
          buttonTo={(route: RouteLocation) => `/auth?next=${to ?? route.path}`}
          footer="Recuerda que es gratuito y sin compromiso"
          dialogName={`redirectToAuth - ${messageKey}`}
        />
      ),
    })
  );
}
