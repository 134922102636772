import AwaitLock from 'await-lock';
import { defineStore } from 'pinia';
import { api, MarketSaleInterest } from 'src/api/client';
import { lockConcurrency } from 'src/util/lockConcurrency';
import { z } from 'zod';

export const useMarketStore = defineStore('market', () => {
  const url = new URL(window.location.href);
  const filterSchema = z
    .object({
      province: z.string().nullable(),
      products: z.array(z.string()).nullable(),
    })
    .partial();
  const filter = ref(
    filterSchema.parse({
      province: url.searchParams.get('province') ?? null,
      products: url.searchParams.getAll('products') ?? null,
    })
  );
  const items = ref<z.infer<typeof MarketSaleInterest>[]>([]);
  const page = ref<number | undefined>(1);
  const initialLoading = ref(true);
  const lock = new AwaitLock();
  const adverts = computedAsync(
    () =>
      api.advertisingAdvertisementList({
        queries: { placement: 'SI' },
      }),
    []
  );

  const fetchNextPage = async () => {
    const filterWas = JSON.stringify(filter.value);
    return lockConcurrency(lock, async () => {
      if (filterWas !== JSON.stringify(filter.value)) {
        initialLoading.value = true;
        items.value = [];
        page.value = 1;
      }
      if (!page.value) {
        initialLoading.value = false;
        return false;
      }
      const { next, results } = await api.interestsMarketList({
        queries: {
          province: filter.value.province ?? undefined,
          products: filter.value.products ?? undefined,
          page: page.value,
        },
      });
      if (results?.length) {
        items.value = [...items.value, ...results];
      }
      if (next) {
        page.value += 1;
      } else {
        page.value = undefined;
      }
      initialLoading.value = false;
    }).then((res) => res ?? page.value !== undefined);
  };

  const refresh = async () => {
    initialLoading.value = true;
    items.value = [];
    page.value = 1;
    await fetchNextPage();
  };

  const currentPage = computed(() => page.value);

  watch(filter, refresh);

  return {
    filter,
    items,
    currentPage,
    fetchNextPage,
    initialLoading,
    refresh,
    adverts,
  };
});
