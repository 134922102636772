<template>
  <div :style="style">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { Theme } from './themes';
import { defaultTheme } from './themes';
import abastoresIcons from 'src/assets/icons.svg';
export interface Props {
  theme?: Theme;
}
const $q = useQuasar();

const props = withDefaults(defineProps<Props>(), {
  theme: () => defaultTheme,
});
const style = computed(() =>
  Object.fromEntries(
    Object.entries(props.theme).map(([key, value]: string[]) => [
      `--q-${key}`,
      value.startsWith('#') ? value : `var(--q-color-${value})`,
    ])
  )
);

// This snipped will enable us to import icons using a-$icon
onBeforeMount(function () {
  $q.iconMapFn = (icon: string) => {
    if (icon.startsWith('a-') === true) {
      const name = icon.substring(2);
      return {
        icon: `svguse:${abastoresIcons}#${name}`,
      };
    }
  };
});
</script>
