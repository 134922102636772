import { DialogChainObject } from 'quasar';

export async function promisifyDialog(dialog: DialogChainObject) {
  return new Promise<boolean>((resolve, reject) => {
    dialog
      .onOk(() => resolve(true))
      .onCancel(() => resolve(false))
      .onDismiss(() => reject());
  });
}
