import { defineStore } from 'pinia';
import { api, V2User, PatchedV2User, PasswordChange } from 'src/api/client';
import { identifyUserMixpanel, track } from 'src/boot/mixpanel';
import { registerNotifications } from 'src/boot/pushNotifications';
import { identifyUserSmartlook } from 'src/boot/smartlook';
import { z } from 'zod';
import init from 'zod-empty';
import { useMarketStore } from './market';
import { useMarketDataStore } from './marketData';
import { decode, JwtPayload } from 'jsonwebtoken';
import { identifyUserAnalytics } from 'src/boot/analytics';

/** Identifies user on all BI providers */
async function identify() {
  identifyUserSmartlook();
  await Promise.all([
    identifyUserMixpanel(),
    registerNotifications(),
    identifyUserAnalytics(),
  ]);
}

/** Reloads all data after auth */
async function reloadData() {
  await Promise.allSettled([
    useMarketDataStore().refresh(),
    useMarketStore().refresh(),
  ]);
}

async function postAuth() {
  await Promise.allSettled([identify(), reloadData()]);
}

const defaultV2JWT = z.object({
  access_token: z.string().nullish().default(null),
  refresh_token: z.string().nullish().default(null),
  user: V2User.nullish().default(null),
});

export const useAuthStore = defineStore({
  id: 'auth',
  persist: true,
  state: () => init(defaultV2JWT),
  getters: {
    isLoggedIn(): boolean {
      return !!this.access_token;
    },
    accessTokenValid(): boolean {
      if (!this.access_token) return false;
      const { exp } = decode(this.access_token) as JwtPayload;
      return !!exp && Date.now() < exp * 1000;
    },
    refreshTokenValid(): boolean {
      if (!this.refresh_token) return false;

      const { exp } = decode(this.refresh_token) as JwtPayload;

      return !!exp && Date.now() < exp * 1000;
    },
  },
  actions: {
    async login(email: string, password: string) {
      const auth = await api.authLoginCreate2({ email, password });
      this.$patch(auth);
      track('Logged in');
      await postAuth();
    },
    async social(access_token: string) {
      // TODO: Handle exceptions
      const auth = await api.authSocialCreate({ access_token });
      this.$patch(auth);
      track('Logged in with Google');
      await postAuth();
    },
    async signup(
      email: string,
      phone_number: string,
      password1: string,
      password2: string,
      legal_terms_ok: boolean
    ) {
      const auth = await api.authRegistrationCreate2({
        email,
        phone_number,
        password1,
        password2,
        legal_terms_ok,
      });
      this.$patch(auth);
      track('Signed up');
      if (window.fbq) {
        window.fbq('track', 'CompleteRegistration');
      }
      await postAuth();
    },
    async update(body: z.infer<typeof PatchedV2User>) {
      const user = await api.coreUsersPartialUpdate(body, {
        params: { id: this.user?.id as number },
      });
      this.$patch({ user });
      track('Finished registration');
    },
    async refresh() {
      const { access: access_token, refresh: refresh_token } =
        await api.authTokenRefreshCreate2({
          access: this.access_token as string,
          refresh: this.refresh_token as string,
        });
      this.$patch({ access_token, refresh_token });
    },
    async change_password(body: z.infer<typeof PasswordChange>) {
      track('Changed password');
      return await api.authPasswordChangeCreate2(body);
    },
    async logout() {
      this.$state = init(defaultV2JWT);
      await reloadData();
      this.router.push('/auth');
      track('Logged out');
    },
    async forgot_password(email: string) {
      track('Sent forgot password');
      return await api.authPasswordResetCreate2({ email });
    },
    async reset_password(
      email: string,
      new_password1: string,
      new_password2: string,
      uid: string,
      token: string
    ) {
      track('Reset password');
      await api.authPasswordResetConfirmCreate2({
        new_password1,
        new_password2,
        uid,
        token,
      });
      await this.login(email, new_password1);
    },
    async delete_account() {
      track('Deleted account');
      await api.authAccountDisableCreate(undefined);
      await reloadData();
      await this.logout();
    },
  },
});
