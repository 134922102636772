import { boot } from 'quasar/wrappers';
import mixpanel from 'mixpanel-browser';
import { useAuthStore } from 'src/stores/auth';
import { version } from '../../package.json';
import { RouteLocation } from 'vue-router';

const VITE_MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
const VITE_SENTRY_RELEASE = import.meta.env.VITE_SENTRY_RELEASE;
const SHOULD_TRACK = VITE_MIXPANEL_TOKEN && VITE_MIXPANEL_TOKEN !== 'false';

export default boot(() => {
  // Shouldn't disable ALL tracking for embedded pages, just navigation
  // SHOULD_TRACK = SHOULD_TRACK && !urlPath.startsWith('/embedded');
  if (SHOULD_TRACK) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: process.env.NODE_ENV === 'development',
      loaded: identifyUserMixpanel,
    });
  }
});

export function identifyUserMixpanel() {
  if (!SHOULD_TRACK) return;
  const { user } = useAuthStore();
  if (user && user.id) {
    mixpanel.identify(user.id.toString());
    mixpanel.people.set({
      $email: user.email,
      $username: user.username,
      $first_name: user.first_name,
      $last_name: user.last_name,
      $last_login: new Date(),
    });
  } else {
    const anonymousId = mixpanel.get_distinct_id();
    mixpanel.identify(anonymousId);
  }
}

export function track(event: string, properties?: Record<string, unknown>) {
  if (!SHOULD_TRACK) return;
  let route: RouteLocation | undefined;
  try {
    route = useRoute();
  } catch (e) {
    console.warn(e);
  }

  mixpanel.track(event, {
    ...properties,
    environment: process.env.NODE_ENV,
    mode: process.env.MODE,
    release: VITE_SENTRY_RELEASE,
    version,
    route: route?.path,
    from_frame: window.frameElement ? true : false,
  });
}
