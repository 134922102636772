import { boot } from 'quasar/wrappers';
import Smartlook from 'smartlook-client';
import { useAuthStore } from 'src/stores/auth';

const VITE_SMARTLOOK_TOKEN = import.meta.env.VITE_SMARTLOOK_TOKEN;
let SHOULD_TRACK = VITE_SMARTLOOK_TOKEN && VITE_SMARTLOOK_TOKEN !== 'false';

export default boot(({ urlPath }) => {
  SHOULD_TRACK = SHOULD_TRACK && !urlPath.startsWith('/embedded');
  if (SHOULD_TRACK) {
    Smartlook.init(VITE_SMARTLOOK_TOKEN, {
      region: 'eu',
    });

    identifyUserSmartlook();
  }
});

export function identifyUserSmartlook() {
  if (!SHOULD_TRACK) return;
  const { user } = useAuthStore();
  if (!user) return;
  if (user.id) {
    if (user.id) {
      Smartlook.identify(user.id, {
        email: String(user.email),
        username: user.username,
        first_name: String(user.first_name),
        last_name: String(user.last_name),
      });
    }
  }
}
