import appleStoreIcon from 'src/assets/download-apple-store.svg';
import googlePlayIcon from 'src/assets/download-google-store.svg';
import { Notify } from 'quasar';
import { track } from 'src/boot/mixpanel';

function goApple() {
  track('Install App modal - Apple Store button clicked');
  window.open('https://apps.apple.com/es/app/abastores/id1666544074', '_blank');
}

function goGoogle() {
  track('Install App modal - Google Play button clicked');
  window.open(
    'https://play.google.com/store/apps/details?id=com.abastores.app',
    '_blank'
  );
}

function promoteAppNotification() {
  Notify.create({
    message: 'Descarga nuestra <b>app móvil</b> y mejora tu experiencia <br>',
    classes: 'promote-app',
    textColor: 'black',
    html: true,
    actions: [
      {
        innerHTML: `<img src=${googlePlayIcon}>`,
        handler: () => goGoogle(),
      },
      {
        innerHTML: `<img src= ${appleStoreIcon}>`,
        handler: () => goApple(),
      },
    ],
    closeBtn: 'X',
    color: 'white',
    progress: true,
    timeout: 8000,
    onDismiss: () => {
      track('Install App modal - Hidden');
    },
  });
}

export function tryPromoteAppNotification() {
  setTimeout(() => {
    promoteAppNotification();
    track('Install App modal - Shown');
  }, 15000);
}

export default function () {
  //The existence of this void function avoids the error 'SyntaxError: ambiguous indirect export: default'
}
