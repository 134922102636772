import { route } from 'quasar/wrappers';
import { createRouter, createWebHistory } from 'vue-router';
import { URLOpenListenerEvent } from '@capacitor/app';
import { setupLayouts } from 'virtual:meta-layouts';
import generatedRoutes from 'virtual:generated-pages';
import { useAuthStore } from 'src/stores/auth';
import { track } from 'src/boot/mixpanel';
import {
  anonRedirectToAuth,
  MESSAGES,
} from 'src/components/dialogs/redirectToAuth';
import { Capacitor } from '@capacitor/core';

const routes = setupLayouts(generatedRoutes);

export default route(function (/* { store, ssrContext } */) {
  // https://forum.quasar-framework.org/topic/7130/capacitor-router-historymode/5
  // const createHistory = process.env.SERVER
  //   ? createMemoryHistory
  //   : process.env.VUE_ROUTER_MODE === 'history'
  //   ? createWebHistory
  //   : createWebHashHistory;
  const createHistory = createWebHistory;

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  Router.beforeEach((to, from) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- matched is recursive, so it breaks when we JSON.stringify it
    const { matched: _, ...safeFrom } = from;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- same deal here
    const { matched: __, ...safeTo } = to;
    const event: Record<string, unknown> = { from: safeFrom, to: safeTo };
    try {
      const authStore = useAuthStore();
      if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        event.redirectedTo = `/auth/?next=${to.path}`;

        if (to.meta.authDialogKey) {
          anonRedirectToAuth(
            to.meta.authDialogKey as keyof typeof MESSAGES,
            to.path
          );

          return false;
        } else {
          return event.redirectedTo as string;
        }
      }

      if (
        authStore.isLoggedIn &&
        !!authStore.user &&
        !authStore.user.registration_completed
      ) {
        if (to.path !== '/auth/finish') {
          event.redirectedTo = '/auth/finish';
          return event.redirectedTo as string;
        }
      }
    } finally {
      if (to.path.includes('embedded') || from.path.includes('embedded'))
        return;
      setTimeout(() => {
        track('Route changed', event);
      }, 0);
    }
  });

  // Deprecated but IOS Universal Links will NOT work without it
  Capacitor.Plugins.App?.addListener(
    'appUrlOpen',
    function (event: URLOpenListenerEvent) {
      const uri = new URL(event.url);
      if (uri.pathname) {
        Router.push({
          path: uri.pathname,
          query: Object.fromEntries(uri.searchParams),
        });
      }
    }
  );

  return Router;
});
