import Axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'qs';
import { useAuthStore } from 'src/stores/auth';

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_URL ?? process.env.VITE_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
});

axios.interceptors.request.use((request) => {
  const authStore = useAuthStore();
  if (authStore.isLoggedIn) {
    if (!authStore.refreshTokenValid) {
      authStore.logout();
      return request;
    }
    request.headers ??= {};
    request.headers.Authorization = `Bearer ${authStore.access_token}`;
  }
  return request;
});

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: async (error) => {
    switch (error.response?.status) {
      case 401:
      case 403:
        // Retry request with token refreshed.
        // originalRequest._retry = true
        // session expired, Refresh Token
        const authStore = useAuthStore();

        if (authStore.isLoggedIn) {
          // if refresh token is valid, authenticate and retry
          if (!authStore.accessTokenValid && authStore.refreshTokenValid) {
            try {
              // Await for token refresh, reconfigure axios and retry
              await authStore.refresh();
              error.config.headers ??= {};
              error.config.headers.Authorization = `Bearer ${authStore.access_token}`;
              return true;
            } catch (error) {
              console.error(error);
            }
          }
          // if refresh token is expired, logout and redirect to login
          await authStore.logout();
          useRouter().push('/auth/login');
          return false;
        }
        break;
    }
    return axiosRetry.isNetworkOrIdempotentRequestError(error);
  },
});
